import React, { CSSProperties } from 'react'
import styled from 'styled-components'

const StyledPath = styled.path`
  fill: ${(props) => props.theme.colors.black};
`

const SvgAppshackFullLogo = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1199.8 190.9"
      style={{ enableBackground: 'new 0 0 1199.8 190.9' } as CSSProperties}
      xmlSpace="preserve"
    >
      <g id="a"></g>
      <g>
        <g>
          <StyledPath
            d="M408.5,75.4l39.7,115.5H328.9l39.8-115.5H408.5z M401.8,158l-13.2-35l-13.3,35H401.8z"
          />
          <StyledPath
            d="M510.3,75.4c20.6,0,37.1,16.5,37.1,37.1c0,20.4-16.5,37.1-37.1,37.1h-29.2v41.3h-32.9V75.4H510.3z
			 M510.3,116.7c2.3,0,4.2-1.9,4.2-4.2s-1.9-4.2-4.2-4.2h-29.2v8.4H510.3z"
          />
          <StyledPath
            d="M609.6,75.4c20.6,0,37.1,16.5,37.1,37.1c0,20.4-16.5,37.1-37.1,37.1h-29.2v41.3h-32.9V75.4H609.6z
			 M609.6,116.7c2.3,0,4.2-1.9,4.2-4.2s-1.9-4.2-4.2-4.2h-29.2v8.4H609.6z"
          />
          <StyledPath
            d="M741.8,116.7c20.6,0,37.1,16.7,37.1,37.1s-16.5,37.1-37.1,37.1h-53.5V158h53.5c2.3,0,4.2-1.9,4.2-4.2
			c0-2.3-1.9-4.2-4.2-4.2h-25c-20.4,0-37.1-16.7-37.1-37.1c0-10.3,4.1-19.6,10.8-26.3c6.8-6.8,16-10.8,26.3-10.8h53.2v32.9h-53.2
			c-2.3,0-4.2,1.9-4.2,4.2s1.9,4.2,4.2,4.2H741.8z"
          />
          <StyledPath
            d="M849.5,75.4h32.9v115.5h-32.9v-41.3h-33.6v41.3H783V75.4h32.9v41.3h33.6V75.4z"
          />
          <StyledPath
            d="M1068,146.7l29.5,14.8c-9.9,17.5-28.7,29.4-50.4,29.4c-32,0-57.8-25.9-57.8-57.8c0-31.8,25.9-57.7,57.8-57.7
			c20.9,0,39.1,11,49.3,27.6l-29.7,14.9c-4.6-6-11.6-9.6-19.6-9.6c-13.8,0-24.9,11.1-24.9,24.8c0,13.8,11.1,24.9,24.9,24.9
			C1055.8,158,1063.5,153.5,1068,146.7z"
          />
          <StyledPath
            d="M1199.8,75.4l-39.5,55.2l39.5,60.3h-39.3l-20.7-31.7l-6.4,8.8v22.9h-32.9V75.4h32.9v36.2l25.9-36.2H1199.8z"
          />
          <StyledPath
            d="M962,75.4l39.7,115.5H882.4l39.8-115.5H962z M955.3,158l-13.2-35l-13.3,35H955.3z"
          />
        </g>
        <StyledPath
          d="M134.1,0L65.8,68.3V22.1L32.9,54.9v46.2L0,134.1v56.8h32.9v-43.2L134.1,46.5l21.6,21.6L32.9,190.9h235.2v-56.8
		L134.1,0z M235.2,158h-123l66.6-66.6l56.3,56.3V158z"
        />
      </g>
    </svg>
  )
}

export default SvgAppshackFullLogo
