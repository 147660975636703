import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React from 'react'
import styled, { css } from 'styled-components'

import useWindowSizes from '../../../../hooks/useWindowSizes'
import { MenuProps } from '../../../../types'

const StyledMenu = styled.nav<{ $open: boolean }>`
  display: flex;
  flex-direction: column;
  background: #0b1316;
  transform: ${({ $open }) => ($open ? 'translateX(0)' : 'translateX(100%)')};
  text-align: left;
  padding: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: transform 0.3s ease-in-out;
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 46px;
  gap: 56px;
`

const CommonLinkStyles = css`
  font-family: 'MadeOuterSans-Thin', sans-serif;
  display: block;
  font-weight: 250;
  font-size: 26px;
  line-height: 32px;
  color: white;
  text-decoration: none;

  &.active {
    color: #aeb1b2;
  }
`
const StyledLink = styled(Link)`
  ${CommonLinkStyles}
`
const StyledAnchorLink = styled(AnchorLink)`
  ${CommonLinkStyles}
`

const MenuMobile = ({ open, setOpen, t, ...props }: MenuProps) => {
  const isHidden = open ? true : false
  const { height } = useWindowSizes()
  return (
    <StyledMenu $open={open} aria-hidden={!isHidden} {...props} style={{ height: `${height}px` }}>
      <LinkContainer>
        <StyledAnchorLink to="/#about" onAnchorLinkClick={() => setOpen(false)} title="about">
          {t('about')}
        </StyledAnchorLink>
        <StyledAnchorLink to="/#ourwork" onAnchorLinkClick={() => setOpen(false)} stripHash>
          {t('ourWork')}
        </StyledAnchorLink>
        <StyledAnchorLink to="/#career" onAnchorLinkClick={() => setOpen(false)} stripHash>
          {t('career')}
        </StyledAnchorLink>
        <StyledLink to="/contact" activeClassName="active" onClick={() => setOpen(false)}>
          {t('contact')}
        </StyledLink>
        <StyledLink to="/press" activeClassName="active" onClick={() => setOpen(false)}>
          {t('press')}
        </StyledLink>
      </LinkContainer>
    </StyledMenu>
  )
}

export default MenuMobile
