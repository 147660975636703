import { create } from "zustand";
import { mountStoreDevtool } from 'simple-zustand-devtools';

type Store = {
    isWorkshopOpen: boolean
    setFalse: () => void
    setTrue: () => void
}

const useWorkshopStore = create<Store>((set) => ({
    isWorkshopOpen: false,
    setFalse: () => set({isWorkshopOpen: false}),
    setTrue: () => set({isWorkshopOpen: true})

}))

// if (process.env.NODE_ENV === 'development') {
//     mountStoreDevtool('Store', useWorkshopStore);
//   }

export default useWorkshopStore