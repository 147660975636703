import { DefaultTheme } from 'styled-components'

import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { variables } from './variables'

export const theme: DefaultTheme = {
  colors,
  breakpoints,
  variables,
}
