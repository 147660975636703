export type IColors = Record<keyof typeof colors, string>

export const colors = {
  black: '#0B1316',
  white: '#FFFFFF',
  gray1: '#E4E5E4',
  gray2: '#AEB1B2',
  gray3: '#777c7e',
  gray4: '#42474A',
  gray5: '#262D30',
}
