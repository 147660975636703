import React, { useEffect } from 'react'

const useConfetti = () => {
  const [confettiExplosion, setConfettiExplosion] = React.useState({
    isExploding: false,
    hasExploded: false,
    confettiSettings: { force: 0.8, duration: 3000, particleCount: 250, width: 1600 },
  })

  // Time to explode
  const HOUR = 13
  const MINUTE = 37

  useEffect(() => {
    const timer = setInterval(() => {
      const { hasExploded } = confettiExplosion
      const now = new Date()
      if (!hasExploded && now.getHours() === HOUR && now.getMinutes() === MINUTE) {
        setConfettiExplosion({ ...confettiExplosion, isExploding: true, hasExploded: true })
      } else {
        setConfettiExplosion({ ...confettiExplosion, isExploding: false })
      }
    }, 2800)
    return () => clearInterval(timer)
  }, [confettiExplosion.hasExploded])

  return confettiExplosion
}

export default useConfetti
