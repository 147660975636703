import styled from 'styled-components'

type HoverColor = 'blue' | 'gray1' | 'gray2' | 'gray3' | 'gray4' | 'white'

export const StyledALink = styled.a<{ $hoverColor: HoverColor }>`
  color: inherit;
  text-decoration: none;
  width: fit-content;
  position: relative;
  padding: 0.2em 0;
  overflow: hidden;

  --color: ${({ theme, $hoverColor }) => theme.colors[$hoverColor]};

  &:hover {
    color: var(--color);
  }
  &:active {
    color: var(--color);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color);
    transition:
      opacity 300ms,
      transform 300ms;
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }

  &:hover::after,
  &:focus::after {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`
