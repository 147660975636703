import { AnimatePresence, motion } from 'framer-motion'
import { navigate } from 'gatsby'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import styled, { ThemeProvider } from 'styled-components'

import { GlobalStyle, Scrollable } from '../../globalStyles/GlobalStyles'
import useConfetti from '../../hooks/useConfetti'
import useScrollProgress from '../../hooks/useScrollProgress'
import useWindowSizes from '../../hooks/useWindowSizes'
import useWorkshopStore from '../../store/workshopStore/workshopStore'
import { theme } from '../../themes'
import AppContextWrapper, { AppContext } from '../AppContextWrapper'
import ButtonFloating from '../Buttons/ButtonFloating/ButtonFloating'
import Footer from '../Navigation/Footer/Footer'
import Header from '../Navigation/Header/Header'
import WorkshopModal from '../WorkshopModal/WorkshopModal'

type Props = {
  children: JSX.Element | JSX.Element[]
  location: {
    state: { scrollToSection: string }
    pathname: string
  }
  data: any
}

const CoverScreen = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.article<{ $avoidScrolling: boolean; $scrollProgress: number }>`
  ${(props) =>
    props.$avoidScrolling
      ? `
        top: ${props.$scrollProgress}px;
        position: fixed;
        left: 0;
        right: 0;
    `
      : ''}
`
export const LayoutContexts = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <AppContextWrapper>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </AppContextWrapper>
  )
}

export const Layout = ({ children, location, data }: Props) => {
  const scrollProgress = useScrollProgress()
  const { loadingShown } = useContext(AppContext)
  const windowSizes = useWindowSizes()
  const startAnimation = scrollProgress > 40
  const [open, setOpen] = useState(false)
  const tdata = JSON.parse(data?.locales?.edges[0].node.data ?? '{}')
  const t = (key: string) => tdata?.[key] ?? key

  const tablet = 768
  const { width: windowWidth } = windowSizes
  const isSmallScreen = useMemo(() => {
    return windowWidth < tablet
  }, [windowWidth])

  const [isWorkshopOpen] = useWorkshopStore((state) => [state.isWorkshopOpen])
  const [scrollOffset, setScrollOffset] = useState(0)

  useEffect(() => {
    if (open) {
      setScrollOffset(scrollProgress)
    } else {
      if (typeof window !== 'undefined') {
        if (isSmallScreen) {
          window.scrollTo(0, scrollOffset)
        }
      }
    }
  }, [open])

  const { isExploding, confettiSettings } = useConfetti()

  const isLandingPage = useMemo(() => {
    return !location.pathname.includes('contact') && !location.pathname.includes('press')
  }, [location.pathname])

  return (
    <>
      <Wrapper id="layoutWrapper" $avoidScrolling={!loadingShown} $scrollProgress={scrollOffset}>
        <Scrollable $avoidScrolling={open && isSmallScreen} />
        {isWorkshopOpen && <WorkshopModal isOpen={isWorkshopOpen} />}
        {children}
        {location.pathname.includes('contact') ? '' : <Footer />}
        {isExploding && (
          <CoverScreen>
            <ConfettiExplosion {...confettiSettings} />
          </CoverScreen>
        )}

        <AnimatePresence mode="popLayout">
          {isLandingPage && startAnimation && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              layout
              exit={{ opacity: 0 }}
              transition={{ type: 'spring' }}
            >
              <ButtonFloating
                type={'button'}
                onClick={() => {
                  navigate('/contact')
                }}
              >
                {t('getInTouch')}
              </ButtonFloating>
            </motion.div>
          )}
        </AnimatePresence>
      </Wrapper>
      {loadingShown && (
        <Header
          t={t}
          open={open}
          setOpen={setOpen}
          scrollProgress={scrollProgress}
          windowSizes={windowSizes}
          isLandingPage={isLandingPage}
        />
      )}
    </>
  )
}
