import { useEffect, useState } from 'react'

const useScrollProgress = (): number => {
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        setScrollY(window.scrollY)
      }

      window.addEventListener('scroll', handleScroll)
      handleScroll()

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return scrollY
}

export default useScrollProgress
