import { createGlobalStyle } from 'styled-components'

import stolzlBookWoff2Font from '../../static/fonts/stolzl/book/stolzl-book.woff2'
import stolzlBookWoffFont from '../../static/fonts/stolzl/book/stolzl-book.woff'
import stolzlBookTtfFont from '../../static/fonts/stolzl/book/stolzl-book.ttf'

import stolzlLightWoff2Font from '../../static/fonts/stolzl/light/stolzl-light.woff2'
import stolzlLightWoffFont from '../../static/fonts/stolzl/light/stolzl-light.woff'
import stolzlLightTtfFont from '../../static/fonts/stolzl/light/stolzl-light.ttf'

import stolzlMediumWoff2Font from '../../static/fonts/stolzl/medium/stolzl-medium.woff2'
import stolzlMediumWoffFont from '../../static/fonts/stolzl/medium/stolzl-medium.woff'
import stolzlMediumTtfFont from '../../static/fonts/stolzl/medium/stolzl-medium.ttf'

import stolzlBoldWoff2Font from '../../static/fonts/stolzl/bold/stolzl-bold.woff2'
import stolzlBoldWoffFont from '../../static/fonts/stolzl/bold/stolzl-bold.woff'
import stolzlBoldTtfFont from '../../static/fonts/stolzl/bold/stolzl-bold.ttf'

import outerSansBlackFont from '../../static/fonts/MADEOuterSans/black/MADEOuterSans-Black.woff2'
import outerSansBlackWoffFont from '../../static/fonts/MADEOuterSans/black/MADEOuterSans-Black.woff'
import outerSansBlackTtfFont from '../../static/fonts/MADEOuterSans/black/MADEOuterSans-Black.ttf'

import outerSansThinFont from '../../static/fonts/MADEOuterSans/thin/MADEOuterSans-Thin.woff2'
import outerSansThinWoffFont from '../../static/fonts/MADEOuterSans/thin/MADEOuterSans-Thin.woff'
import outerSansThinTtfFont from '../../static/fonts/MADEOuterSans/thin/MADEOuterSans-Thin.ttf'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "MadeOuterSans-Black";
    src: url(${outerSansBlackFont}) format('woff2'),
      url(${outerSansBlackWoffFont}) format('woff'),
      url(${outerSansBlackTtfFont}) format('truetype');
  }

  @font-face {
    font-family: "MadeOuterSans-Thin";
    src: url(${outerSansThinFont}) format('woff2'),
      url(${outerSansThinWoffFont}) format('woff'),
      url(${outerSansThinTtfFont}) format('truetype');
  }

  @font-face {
    font-family: "Stolzl-Thin";
    src: url(${stolzlLightWoff2Font}) format('woff2'),
      url(${stolzlLightWoffFont}) format('woff'),
      url(${stolzlLightTtfFont}) format('truetype');

  }
  @font-face {
    font-family: "Stolzl-Book";
    src: url(${stolzlBookWoff2Font}) format('woff2'),
      url(${stolzlBookWoffFont}) format('woff'),
      url(${stolzlBookTtfFont}) format('truetype');
  }

  @font-face {
    font-family: "Stolzl-Medium";
    src:  url(${stolzlMediumWoff2Font}) format('woff2'),
      url(${stolzlMediumWoffFont}) format('woff'),
      url(${stolzlMediumTtfFont}) format('truetype');
  }

  @font-face {
    font-family: "Stolzl-Bold";
    src:  url(${stolzlBoldWoff2Font}) format('woff2'),
      url(${stolzlBoldWoffFont}) format('woff'),
      url(${stolzlBoldTtfFont}) format('truetype');
  }

  * {
    box-sizing: border-box;
    margin: 0;
  }

  html {
    scroll-padding-top: 80px;
        
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */  
  }

  body {
    font-family: "Stolzl-Book", sans-serif;
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    margin: 0;
    position: relative;
    
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */  
  }

  body::-webkit-scrollbar {
    display: none;
  }
  
  h1,h2,h3,h4,h5,h6 {
    font-family: "MadeOuterSans-Black", sans-serif;
  }

  h1 {
    font-weight: 900;
    font-size: 36px;
    line-height: 44px;

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      font-size: 45px;
      line-height: 52px;
    }
  }

  h2 {
    font-weight: 900;
    font-size: 45px;
    line-height: 52px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.766;
    font-weight: 400;
    letter-spacing: 0.4px;

    b {
      font-family: 'Stolzl-Bold', sans-serif;
      font-weight: 700;
    }
  }
`

export const Scrollable = createGlobalStyle<{ $avoidScrolling: boolean }>`
  body {
    overflow: ${({ $avoidScrolling }) => ($avoidScrolling ? 'hidden' : 'auto')};
  }
`
