import React, { createContext, useCallback, useState } from 'react'

export const AppContext = createContext({
  loadingShown: false,
  toggleLoading: (value: boolean) => {
    console.log(value)
  },
})

export const AppContextWrapper = ({ children }: { children: React.ReactNode }) => {
  const [loadingShown, setLoadingShown] = useState(false)
  const toggleLoading = useCallback((value: boolean) => {
    setLoadingShown(value)
  }, [])

  return <AppContext.Provider value={{ loadingShown, toggleLoading }}>{children}</AppContext.Provider>
}

export default AppContextWrapper
