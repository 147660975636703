import React from 'react'

const SvgLinkedIn = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 1H1.46875C0.9375 1 0.5 1.46875 0.5 2.03125V14C0.5 14.5625 0.9375 15 1.46875 15H13.5C14.0312 15 14.5 14.5625 14.5 14V2.03125C14.5 1.46875 14.0312 1 13.5 1ZM4.71875 13H2.65625V6.34375H4.71875V13ZM3.6875 5.40625C3 5.40625 2.46875 4.875 2.46875 4.21875C2.46875 3.5625 3 3 3.6875 3C4.34375 3 4.875 3.5625 4.875 4.21875C4.875 4.875 4.34375 5.40625 3.6875 5.40625ZM12.5 13H10.4062V9.75C10.4062 9 10.4062 8 9.34375 8C8.25 8 8.09375 8.84375 8.09375 9.71875V13H6.03125V6.34375H8V7.25H8.03125C8.3125 6.71875 9 6.15625 10 6.15625C12.0938 6.15625 12.5 7.5625 12.5 9.34375V13Z"
        fill="#42474A"
      />
    </svg>
  )
}

export default SvgLinkedIn
