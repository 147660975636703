import type { GatsbyBrowser } from 'gatsby'
import * as React from 'react'

import { Preloader } from './src/components/Animations/Preloader'
import { Layout, LayoutContexts } from './src/components/Layout'

import ReactDOM from "react-dom/client";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  return (
      <Layout {...props}>
        <Preloader />
        {element}
      </Layout>
  )
}
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return <LayoutContexts>{element}</LayoutContexts>
}
