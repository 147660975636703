import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import styled from 'styled-components'

import { AppContext } from '../../AppContextWrapper'
import NewLogoAnimation from '../NewLogoAnimation'

const PreloaderContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.black};
`

const PreloaderAnimation = {
  initial: { opacity: 1 },
  animate: { opacity: 0, transitionEnd: { display: 'none' } },
  transition: { delay: 2.8, duration: 0.2, ease: 'easeInOut' },
}

export const Preloader = () => {
  const { toggleLoading, loadingShown } = useContext(AppContext)


  if (loadingShown) {
    return null
  }

  return (
    <PreloaderContainer {...PreloaderAnimation} onAnimationComplete={() => toggleLoading(true)}>
      <NewLogoAnimation />
    </PreloaderContainer>
  )
}

export default Preloader
