import { useEffect, useState } from 'react'

const useWindowSizes = (): { width: number; height: number } => {
  const [sizes, setSizes] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        const sizes = { width: window.innerWidth, height: window.innerHeight }
        setSizes(sizes)
      }

      window.addEventListener('resize', handleResize)
      handleResize()
      // Cleanup function to remove event listener when component unmounts
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return sizes
}

export default useWindowSizes
