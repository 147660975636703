import React, { CSSProperties } from 'react'
import styled from 'styled-components'

const StyledSvg = styled.svg`
  width: 32px;
`

const LogoSVG = () => {
  return (
    <StyledSvg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 422 300.5"
      style={{ enableBackground: 'new 0 0 422 300.5' } as CSSProperties}
      xmlSpace="preserve"
    >
      <g id="a"></g>
      <g>
        <path
          style={{ fill: 'white' }}
          d="M211,0L103.6,107.5V34.7L51.8,86.5v72.8L0,211v89.4h51.8v-68L211,73.2l34,34L51.8,300.4H422V211L211,0z
      M370.3,248.7H176.7l104.9-104.9l88.6,88.6V248.7z"
        />
      </g>
    </StyledSvg>
  )
}

export default LogoSVG
