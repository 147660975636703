import React from 'react'

const NoaFamily = () => {
  return (
    <svg width="220" height="10" viewBox="0 0 220 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1847_25312)">
        <path
          d="M170.5 0.369263V9.95498H172.229V6.0264H176.314V4.61212H172.229V1.94069H176.943V0.369263H170.5Z"
          fill="black"
        />
        <path
          d="M181.971 2.41212L183.7 6.34069H180.243L181.971 2.41212ZM181.029 0.369263L176.786 9.79783H178.514L179.457 7.59783H184.171L185.114 9.79783H186.843L182.6 0.369263H181.029Z"
          fill="black"
        />
        <path
          d="M196.743 0.369263L193.757 6.34069L190.929 0.369263H188.414V9.95498H190.143V2.56926L193.443 9.16926H194.229L197.529 2.56926V9.95498H199.1V0.369263H196.743Z"
          fill="black"
        />
        <path d="M203.5 0.369263H201.929V9.79783H203.5V0.369263Z" fill="black" />
        <path d="M206.171 0.369263V9.95498H212.143V8.38355H207.743V0.369263H206.171Z" fill="black" />
        <path
          d="M218.271 0.369263L215.6 4.76926L213.086 0.369263H211.357L214.814 6.18355V9.95498H216.543V6.18355L220 0.369263H218.271Z"
          fill="black"
        />
        <path
          d="M5.02857 2.41212L6.75714 6.34069H3.3L5.02857 2.41212ZM4.24286 0.369263L0 9.95498H1.72857L2.67143 7.75498H7.38571L8.32857 9.95498H10.0571L5.81429 0.526406H4.24286V0.369263Z"
          fill="black"
        />
        <path
          d="M23.5715 0.369263L20.7429 6.34069L17.9143 0.369263H15.4V9.95498H16.9715V2.56926L20.2715 9.16926H21.2143L24.5143 2.56926V9.95498H26.0857V0.369263H23.5715Z"
          fill="black"
        />
        <path
          d="M28.7571 0.369263V9.95498H35.6714V8.38355H30.3286V5.86926H34.8857V4.45498H30.3286V1.94069H35.5143V0.369263H28.7571Z"
          fill="black"
        />
        <path
          d="M46.0428 0.369263L43.2143 6.34069L40.2285 0.369263H37.8714V9.95498H39.4428V2.56926L42.7428 9.16926H43.5285L46.9857 2.56926V9.95498H48.5571V0.369263H46.0428Z"
          fill="black"
        />
        <path
          d="M52.8 8.38363H55.3143C55.9429 8.38363 56.4143 8.22649 56.7286 8.06935C57.0429 7.9122 57.2 7.59792 57.2 6.96935C57.2 6.18363 56.5714 5.7122 55.1571 5.7122H52.8V8.38363ZM52.8 4.45506H54.8429C55.9429 4.45506 56.5714 3.98363 56.5714 3.19792C56.5714 2.72649 56.4143 2.4122 56.1 2.25506C55.7857 2.09792 55.3143 1.94077 54.8429 1.94077H52.8V4.45506ZM55.3143 9.95506H51.2286V0.526489H54.8429C55.4714 0.526489 56.1 0.526489 56.5714 0.683632C57.0429 0.840775 57.3572 0.997918 57.6714 1.3122C58.1429 1.78363 58.3 2.4122 58.3 3.04077C58.3 3.82649 57.9857 4.29792 57.6714 4.76935C57.5143 4.92649 57.3571 4.92649 57.3571 5.08363C57.3571 5.08363 57.2 5.24077 57.0429 5.24077C57.6714 5.39792 58.1429 5.55506 58.4571 6.02649C58.7714 6.49792 58.9286 6.96935 58.9286 7.59792C58.9286 8.22649 58.7714 8.85506 58.3 9.32649C57.5143 9.64077 56.7286 9.95506 55.3143 9.95506Z"
          fill="black"
        />
        <path
          d="M60.9714 0.369263V9.95498H67.7286V8.38355H62.5429V5.86926H67.1V4.45498H62.5429V1.94069H67.5714V0.369263H60.9714Z"
          fill="black"
        />
        <path
          d="M75.4286 4.92649C75.7428 4.6122 75.9 4.14077 75.9 3.5122C75.9 2.88363 75.7428 2.4122 75.4286 2.25506C75.1143 2.09792 74.4857 1.94077 73.7 1.94077H71.6571V5.39792H73.7C74.4857 5.24077 75.1143 5.08363 75.4286 4.92649ZM77.4714 3.5122C77.4714 5.08363 76.8428 6.02649 75.4286 6.49792L77.9428 9.95506H75.9L73.7 6.8122H71.6571V9.95506H70.0857V0.526489H73.5428C74.9571 0.526489 76.0571 0.840775 76.6857 1.3122C77.1571 1.62649 77.4714 2.4122 77.4714 3.5122Z"
          fill="black"
        />
        <path
          d="M90.5143 2.72644C89.8857 2.09787 89.1 1.78359 88.1571 1.78359C87.2143 1.78359 86.4285 1.94073 85.8 2.72644C85.1714 3.35502 84.8571 4.14073 84.8571 5.08359C84.8571 6.02644 85.1714 6.81216 85.8 7.44073C86.4285 8.0693 87.2143 8.38359 88.1571 8.38359C89.1 8.38359 89.8857 8.0693 90.5143 7.44073C91.1428 6.81216 91.4571 6.02644 91.4571 5.08359C91.4571 4.14073 91.1428 3.35502 90.5143 2.72644ZM91.7714 8.54073C90.8285 9.48359 89.5714 9.95501 88.1571 9.95501C86.7428 9.95501 85.4857 9.48359 84.5428 8.54073C83.6 7.59787 83.1285 6.49787 83.1285 5.08359C83.1285 3.6693 83.6 2.5693 84.5428 1.62644C85.6428 0.683587 86.7428 0.212158 88.1571 0.212158C89.5714 0.212158 90.8285 0.683587 91.7714 1.62644C92.7143 2.5693 93.1857 3.6693 93.1857 5.08359C93.1857 6.49787 92.7143 7.75501 91.7714 8.54073Z"
          fill="black"
        />
        <path
          d="M95.2286 0.369263V9.95498H96.8V6.0264H101.043V4.61212H96.8V1.94069H101.514L101.671 0.369263H95.2286Z"
          fill="black"
        />
        <path d="M106.543 0.369263V1.94069H109.371V9.95498H111.1V1.94069H113.929V0.369263H106.543Z" fill="black" />
        <path
          d="M121.943 0.369263V4.61212H117.229V0.369263H115.657V9.95498H117.229V6.0264H121.943V9.95498H123.514V0.369263H121.943Z"
          fill="black"
        />
        <path
          d="M126.186 0.369263V9.95498H133.1V8.38355H127.757V5.86926H132.314V4.45498H127.757V1.94069H132.943V0.369263H126.186Z"
          fill="black"
        />
        <path
          d="M160.757 2.56926L162.014 6.34069H159.657L160.757 2.56926ZM159.5 0.369263L156.357 9.79783H158.714L159.186 8.2264H162.8L163.271 9.79783H165.629L162.171 0.369263H159.5Z"
          fill="black"
        />
        <path
          d="M145.986 0.369263V6.49783L142.214 0.369263H139.7V9.95498H141.9V3.98355L145.514 9.95498H148.029V0.369263H145.986Z"
          fill="black"
        />
        <path
          d="M153.057 5.86926C152.114 5.86926 151.329 5.08355 151.329 4.14069C151.329 3.19783 152.114 2.41212 153.057 2.41212C154 2.41212 154.786 3.19783 154.786 4.14069C154.786 5.08355 154 5.86926 153.057 5.86926ZM153.057 0.369263C151.014 0.369263 149.286 2.09783 149.286 4.14069C149.286 6.18355 151.014 7.91212 153.057 7.91212C155.1 7.91212 156.829 6.18355 156.829 4.14069C156.829 1.94069 155.1 0.369263 153.057 0.369263Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1847_25312">
          <rect width="220" height="9.74286" fill="white" transform="translate(0 0.212158)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NoaFamily
