import React from 'react'
import styled from 'styled-components'

export type BurgerProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const StyledBurger = styled.button<{ $open: boolean }>`
  top: 30%;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  overflow: visible;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  transform: ${({ $open }) => ($open ? 'rotate(-180deg)' : 'rotate(0)')};
  transition: transform 0.3s linear;
`

const StyledSpan = styled.span<{
  $open: boolean
  $transformOpen: string
  $transformCLose: string
  $opacityOpen?: string
  $opacityClose?: string
}>`
  width: 100%;
  height: 13%;
  background: white;
  border-radius: 10px;
  position: relative;
  transform-origin: 1px;
  opacity: ${({ $open, $opacityOpen, $opacityClose }) => ($open && $opacityOpen ? $opacityOpen : $opacityClose)};
  transform: ${({ $open, $transformOpen, $transformCLose }) => ($open ? $transformOpen : $transformCLose)};
  transition: all 0.3s linear;
`

const Burger = ({ open, setOpen, ...props }: BurgerProps) => {
  const isExpanded = open ? true : false

  return (
    <StyledBurger
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      $open={isExpanded}
      onClick={() => setOpen(!open)}
      {...props}
    >
      <StyledSpan $open={open} $transformOpen="rotate(48deg)" $transformCLose="rotate(0)" />
      <StyledSpan
        $open={open}
        $transformOpen="translateX(20px)"
        $transformCLose="translateX(0)"
        $opacityOpen="0"
        $opacityClose="1"
      />
      <StyledSpan $open={open} $transformOpen="rotate(-47deg)" $transformCLose="rotate(0)" />
    </StyledBurger>
  )
}

export default Burger
