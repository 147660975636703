import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import siren from '../../images/Workshop/siren.gif'
import workshopImage from '../../images/Workshop/workshop.png'
import useWorkshopStore from '../../store/workshopStore/workshopStore'

interface Props {
  isOpen: boolean
}

const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.isOpen ? 'inline-block' : 'none')};
  width: 100vw;
  height: 100dvh;
  height: 100vh;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.6);
`

const BackWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ModalWrapper = styled.div<{ top: number; left: number; delay: number }>`
  width: 968px;
  height: 552px;
  background-color: green;
  display: flex;
  z-index: 55;
  position: fixed;
  top: ${(props) => props.top}%;
  left: ${(props) => props.left}%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: ${appear} 0.1s linear forwards;
  animation-delay: ${(props) => props.delay}s;
`

const ImgBox = styled.div`
  display: none;
  flex: 1;
  background-image: url(${workshopImage});
  background-size: contain;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
  }
`

const InfoBox = styled.div`
  flex: 2;
  background: linear-gradient(102.85deg, #ffcd4d 46.02%, #ffffff 64.56%, #ffcd4d 83.1%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Title = styled.h2`
  color: #24ff00;
  font-family: 'Comic Sans MS';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  text-shadow: 3px 5px 4px rgba(0, 0, 0, 0.85);
`

const TextBox = styled.div`
  width: 450px;
  height: 370px;
  background-color: white;
  border: 3px dashed #ff0000;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 40px;
  text-align: center;
`

const P = styled.p`
  color: black;
  font-family: 'Times New Roman';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
`

const StyledList = styled.ul`
  color: #ff0000;
  font-family: 'Comic Sans MS';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  margin: 25px 60px;
`

const StyledLink = styled(Link)``

const Img = styled.img`
  width: 65px;
  height: 65px;
`

const WorkshopModal = (props: Props) => {
  const { isOpen } = props
  const [setFalse] = useWorkshopStore((state) => [state.setFalse])
  const [canClose, setCanClose] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setCanClose(true)
    }, 1500)
    return () => clearInterval(interval)
  }, [])

  const modalValues = [
    { key: 1, top: 55, left: 46, delay: 0 },
    { key: 2, top: 59, left: 47, delay: 0.2 },
    { key: 3, top: 40, left: 55, delay: 0.4 },
    { key: 3, top: 45, left: 57, delay: 0.6 },
    { key: 3, top: 42, left: 49, delay: 0.8 },
    { key: 6, top: 50, left: 50, delay: 0.10 },
  ]

  return (
    <Wrapper isOpen={isOpen}>
      <BackWrapper onClick={() => (canClose ? setFalse() : null)}></BackWrapper>
      {modalValues.map((values) => (
        <ModalWrapper key={values.key} top={values.top} left={values.left} delay={values.delay}>
          <ImgBox />
          <InfoBox>
            <FlexRow>
              <Img src={siren} alt="siren alarm" />
              <Title>CONGRATULATIONS!!!!</Title>
              <Img src={siren} alt="siren alarm" />
            </FlexRow>
            <TextBox>
              <P>YOU HAVE WON A FREE WORKSHOP SESSION</P>
              <StyledList>
                <li>DESIGN</li>
                <li>PROGRAMMING</li>
                <li>INTERNET</li>
                <li>HOMEPAGE</li>
              </StyledList>
              <P>HOW CAN WE HELP YOU?</P>
              <StyledLink to="/contact?code=futureofapps" onClick={() => setFalse()}>
                CLICK HERE TO CLAIM YOUR PRIZE
              </StyledLink>
            </TextBox>
          </InfoBox>
        </ModalWrapper>
      ))}
    </Wrapper>
  )
}

export default WorkshopModal
