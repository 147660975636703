import { globalHistory } from '@reach/router'
import { useEffect, useState } from 'react'

const usePath = () => {
  const [path, setPath] = useState<string>('')

  useEffect(() => {
    return globalHistory.listen(() => {
      setPath(window.location.pathname)
    })
  }, [])
  return path
}

export default usePath
