import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React from 'react'
import styled, { css } from 'styled-components'
import { MenuProps } from '../../../../types'

const StyledMenu = styled.div<{ $open: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  right: 0;
  transform: ${({ $open }) => ($open ? 'translateX(0)' : 'translateX(30%)')};
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;

  z-index: ${({ $open }) => ($open ? 1 : -10)};
`

const CommonLinkStyles = css<{ $open: boolean }>`
  font-family: 'Stolzl-Book', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 2px;
  text-decoration: none;
  text-align: center;
  color: white;
  margin-right: ${({ $open }) => ($open ? '48px' : 0)};
  transition: margin-right 0.3s linear;

  &.active.text {
    color: #fdb714;
    border-bottom: 1px solid #fdb714;
  }
  &:hover {
    color: #aeb1b2;
  }
  &:active {
    color: #aeb1b2;
  }
`

const StyledLink = styled(Link)<{ $open: boolean }>`
  ${CommonLinkStyles}
`
const StyledAnchorLink = styled(AnchorLink)<{ $open: boolean }>`
  ${CommonLinkStyles}
`

const MenuDesktop = (props: MenuProps) => {
  const { open, setOpen, $isHomePage, t } = props

  return (
    <StyledMenu $open={open}>
      <StyledAnchorLink
        $open={open}
        to="/#about"
        onAnchorLinkClick={() => !$isHomePage && setOpen(false)}
        title="about"
      >
        {t('about')}
      </StyledAnchorLink>
      <StyledAnchorLink $open={open} to="/#ourwork" onAnchorLinkClick={() => !$isHomePage && setOpen(false)} stripHash>
        {t('ourWork')}
      </StyledAnchorLink>
      <StyledAnchorLink $open={open} to="/#career" onAnchorLinkClick={() => !$isHomePage && setOpen(false)} stripHash>
        {t('career')}
      </StyledAnchorLink>
      <StyledLink $open={open} to="/contact" activeClassName="active" onClick={() => setOpen(false)}>
        {t('contact')}
      </StyledLink>
      <StyledLink $open={open} to="/press" activeClassName="active" onClick={() => setOpen(false)}>
        {t('press')}
      </StyledLink>
    </StyledMenu>
  )
}

export default MenuDesktop
