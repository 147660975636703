import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  type: 'button' | 'submit' | 'reset' | undefined
  children?: ReactNode
  width?: string
  onClick?: () => void
}

const StyledButton = styled.button<{ width?: string }>`
  width: ${(props) => `${props.width}`};

  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 22;

  display: none;

  justify-content: space-between;
  align-items: center;
  gap: 10px;

  --bgColor: #42474a;
  background-color: var(--bgColor);
  color: white;
  border: 1px solid var(--bgColor);
  border-radius: 100px;
  padding: 16px 32px;
  font-family: 'Stolzl-Book', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: #232b2d;
  }

  &:active {
    background-color: white;
    color: black;

    path {
      fill: ${(props) => props.theme.colors.black};
    }
  }

  svg {
    fill: #fff;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 16px 48px;
    bottom: 20px;
    right: 40px;
    display: flex;
  }
`

const rocketSVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.25 5.5C14.25 6.46875 13.4375 7.25 12.5 7.25C11.5312 7.25 10.75 6.46875 10.75 5.5C10.75 4.5625 11.5312 3.75 12.5 3.75C13.4375 3.75 14.25 4.5625 14.25 5.5ZM12.5 6.25C12.9062 6.25 13.25 5.9375 13.25 5.5C13.25 5.09375 12.9062 4.75 12.5 4.75C12.0625 4.75 11.75 5.09375 11.75 5.5C11.75 5.9375 12.0625 6.25 12.5 6.25ZM4.6875 10H1.5C1.3125 10 1.15625 9.9375 1.0625 9.78125C0.96875 9.625 0.96875 9.4375 1.03125 9.28125L2.625 6.125C2.96875 5.4375 3.65625 5 4.40625 5H7.21875C7.3125 4.875 7.375 4.78125 7.46875 4.65625C10.0312 0.875 13.8438 0.75 16.0938 1.1875C16.4688 1.25 16.75 1.53125 16.8125 1.90625C17.25 4.15625 17.125 7.96875 13.3438 10.5312C13.2188 10.625 13.0938 10.6875 13 10.7812V13.5938C13 14.3438 12.5625 15.0312 11.875 15.375L8.71875 16.9688C8.5625 17.0312 8.375 17.0312 8.21875 16.9375C8.0625 16.8438 8 16.6875 8 16.5V13.25C7.71875 13.375 7.46875 13.4688 7.25 13.5625C6.90625 13.7188 6.46875 13.625 6.1875 13.3438L4.59375 11.7812C4.3125 11.4688 4.21875 11.0625 4.40625 10.6875C4.46875 10.5 4.5625 10.25 4.6875 10ZM6.875 12.625C8.21875 12.0625 11.2188 10.75 12.7812 9.71875C16.0312 7.5 16.2188 4.21875 15.8438 2.15625C13.75 1.78125 10.5 1.96875 8.28125 5.21875C7.25 6.75 5.90625 9.71875 5.3125 11.0625L6.875 12.625ZM11.4375 14.4688C11.7812 14.3125 12 13.9688 12 13.5938V11.3438C11.0312 11.875 9.9375 12.375 9 12.8125V15.7188L11.4375 14.4688ZM3.53125 6.5625L2.28125 9H5.15625C5.59375 8.0625 6.125 6.96875 6.65625 6H4.40625C4.03125 6 3.6875 6.21875 3.53125 6.5625Z"
      fill="white"
    />
  </svg>
)

const ButtonFloating = (props: Props) => {
  return (
    <StyledButton type={props.type} onClick={props.onClick} width={props.width}>
      {props.children}
      {rocketSVG}
    </StyledButton>
  )
}

export default ButtonFloating
