import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

const AnimateSvgLogo = {
  initial: { opacity: 0, scale: 1, pathLength: 0, fill: 'rgba(255, 255, 255, 0)' },
  animate: { opacity: [1, 0], scale: 0.7, pathLength: 1, fill: 'rgba(255, 255, 255, 1)' },
  transition: {
    default: { duration: 2.4, ease: 'easeInOut' },
    fill: { delay: 1.4, duration: 0.7, ease: 'easeInOut' },
    scale: { delay: 2.5, duration: 0.3, ease: 'easeIn' },
    opacity: { delay: 2.5, duration: 0.3 },
    // scale: { delay: 0.1, duration: 1.4, ease: 'easeIn', type: 'spring' },
  },
}

const SvgContainer = styled.div`
  overflow: visible;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
`

const NewLogoAnimation = () => {
  return (
    <SvgContainer>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 422 300.5"
        xmlSpace="preserve"
        width="200"
        height="100%"
      >
        <g>
          <motion.path
            {...AnimateSvgLogo}
            fill="#0B1316"
            d="M211,0L103.6,107.5V34.7L51.8,86.5v72.8L0,211v89.4h51.8v-68L211,73.2l34,34L51.8,300.4H422V211L211,0z
		 M370.3,248.7H176.7l104.9-104.9l88.6,88.6V248.7z"
          />
        </g>
      </svg>
    </SvgContainer>
  )
}

export default NewLogoAnimation
